import {defineStore} from "pinia";
import {systemApi} from "@/api/system";
import {useAuthStore} from "@shared/store/useAuthStore";
import {dictionaryApi} from "@/api/dictionary";
import _ from 'lodash'

export const useConfigStore = defineStore('config', {
    state: () => ({
        config: {
            name: "信息管理空间",
            logoUuid: "",
            iconUrl: "",
            qiqiuyunPrivate: false,
            useQiqiuyun: false,
        },
        dictionary: {}
    }),

    getters: {
        getConfig(state) {
            return state.config;
        },
        getDictionary(state) {
            return state.dictionary;
        },
    },

    actions: {
        async init() {
            let authStore = useAuthStore();
            if (!authStore.isLogin || !authStore.isAdmin) {
                return
            }

            const config = await systemApi.getSetting();
            if (config) {
                this.config = config;
            }

            const dictionary = await dictionaryApi.findAll();

            if (dictionary) {
                this.dictionary = _.keyBy(dictionary,'id');
            }

        }
    }

});
