import {client} from "@shared/api-client";

export const systemApi = {
    // 获取设置
    getSetting: async () => {
        return client.get("/api-admin/setting/getWebsiteSettings");
    },
    // 更新设置
    updateSetting: async (params = {
        name: "",
        logoUuid: "",
        subTitle: "",
        iconUuid: '',
        icpInfo: '',
        icpLink: '',
        bottomNavigation: true
    }) => {
        return client.post("/api-admin/setting/updateWebsiteSet", params);
    },
    getFenKeSetting: async () => {
        return client.get("/api-admin/public/getFenKeSetting");
    },
    getOpenSetting: async (id) => {
        return client.get("/api-admin/public/getWebsiteSettingsByCorpId", {params: {id}});
    },
}
