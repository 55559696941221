import BasicLayout from "@/layouts/BasicLayout";

export const superAdminRoutes = [{
    path: '/',
    name: 'index',
    meta: {title: 'Home'},
    component: BasicLayout,
    redirect: '/business/business',
    children: [
        {
            path: '/business/business',
            name: 'business',
            meta: {title: '企业管理'},
            component: () => import('../views/business/businessManagePage.vue'),
        },
    ]
}
]
