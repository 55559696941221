import {client} from "@shared/api-client";

/*
类型名称: name
类型编码: code
*/
export const orgCategorizeApi = {
    create: async (params) => {
        return client.post("/api-admin/orgCategorize/create", params);
    },
    search: async (params) => {
        return client.get("/api-admin/orgCategorize/search", {params: params});
    },
    update: async (params) => {
        return client.post("/api-admin/orgCategorize/update", params);
    },
    delete: async (params = {id: 0}) => {
        return client.post("/api-admin/orgCategorize/delete", params);
    },
    checkCodeExist: async (params = {id: 0, code: ""}) => {
        return client.post("/api-admin/orgCategorize/checkCodeExist", params);
    },
    getById: async (id) => {
        return client.get("/api-admin/orgCategorize/getById", {params: {id}});
    },
}

