<template>
  <pro-layout
      :locale="locale"
      v-model:openKeys="state.openKeys"
      v-model:collapsed="state.collapsed"
      v-model:selectedKeys="state.selectedKeys"
      :menu-data="menuData.data"
      v-bind="layoutConf"

  >
    <template #rightContentRender>
      <div style="display: flex;align-items: center;padding-right: 10px; ">
        <skin-filled @click="changeLayout"
                     :style="{color: layoutConf.layout=='mix' ? '#fff':'#000','margin-right': '10px','font-size': '20px'}"/>
        <span v-if="store.user" :style="{color: layoutConf.layout=='mix' ? '#fff':'#000'}">您好，{{
            store.user.username
          }}</span>
        <a-button type="link" @click="onLogout">退出</a-button>
      </div>
    </template>

    <template #menuItemRender="{ item, icon }">
      <a-menu-item
          v-if="item.status"
          :key="item.path"
          :disabled="item.meta?.disabled"
          :danger="item.meta?.danger"
          :icon="icon"
          @click="topMenuClick(item)"
      >
        <span class="ant-pro-menu-item" v-if="layoutConf.layout == 'mix'">
            <span class="ant-pro-menu-item-title">{{ item.meta.title }}</span>
        </span>
      </a-menu-item>
    </template>
    <template #menuHeaderRender v-if="layoutConf.layout == 'top'">
      <!-- logo 标题 -->
      <img :src="layoutConf.logo" alt="">
      <h1>{{configStore.config?.name}}</h1>
      <!-- 顶部导航 -->
      <div class="nav-top">
        <ul class="one-level">
          <li v-for="item in navData" :key="item.id" @mouseenter="isShowMask = true" @mouseleave="isShowMask = false">
            <span class="first-level-nav" @click=" item.children ? '' : topMenuClick(item)">{{ item.name }}</span>
            <down-outlined class="bottom-icon" v-if="item.children" style="margin-left: 5px;"/>
          </li>
          <!-- 顶部导航详情 -->
          <div :class="{'top-nav-mask':true,'top-nav-mask-show':isShowMask}" @mouseenter="isShowMask = true"
               @mouseleave="isShowMask = false">
            <ul class="tow-level">
              <li v-for="items in navData" :key="items.id">
                <span class="none-child" @click="topMenuClick(items)" v-if="!items.children">{{ items.name }}</span>
                <div class="tow-level-nav" v-else>
              <li v-for="ele in items.children" :key="ele.id" :style="{ height: ele.children ? '100%': '30px' }">
                <template v-if="ele.status">
                  <span @click="topMenuClick(ele)" v-if="!ele.children">{{ ele.name }}</span>
                  <div class="have-three-level-nav" v-else>
                    <a-collapse v-model:activeKey="activeKey" expandIconPosition="right">
                      <a-collapse-panel :key="ele.id" :header="ele.name">
              <li v-for="el in ele.children" :key="el.id">
                <span v-if="el.status" @click="topMenuClick(el)">{{ el.name }}</span>
              </li>
              </a-collapse-panel>
              </a-collapse>
          </div>
    </template>
                </li>
              </div>
            </li>
          </ul>
        </div>
       </ul>
      </div>
    </template>

    <router-view/>
  </pro-layout>
</template>

<script setup>
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {systemApi} from "@/api/system";
import {navigationApi} from "@/api/navigation";
import {useAuthStore} from "@shared/store/useAuthStore";
import {useStore} from '@shared/store/useRouterStore'
import {useConfigStore} from "@shared/store/useConfigStore";
import {events} from "@/mitt/bus";
import {fileApi} from "@/api/file";
import {userApi} from "@/api/user";

const configStore = useConfigStore()

const stores = useStore()
const store = useAuthStore();
const locale = (i18n) => i18n;
const router = useRouter();
// let {menuData} = getMenuData(clearMenuItem(router.getRoutes()));
let menuData = reactive({
  data: []
})
onMounted(() => {
  getNavList()
  document.querySelector('.ant-pro-top-nav-header-menu').style.display='none'
})

const getNavList = async () => {
  const roles = localStorage.getItem("roles")
  if (roles == "SUPER_ADMIN") {
    menuData.data = [{
      "id": 14,
      "parentId": null,
      "weight": 4,
      "name": "首页",
      "seq": 0,
      "newWin": false,
      "status": true,
      "url": "/welcome",
      "path": "/welcome",
      "meta": {"title": "首页"},
    }, {
      "id": 11,
      "parentId": null,
      "weight": 1,
      "name": "企业管理",
      "seq": 1,
      "newWin": false,
      "status": true,
      "url": "/business/business",
      "path": "/business/business",
      "meta": {"title": "企业管理"},
      "children": [{
        "id": 30,
        "parentId": 11,
        "weight": 0,
        "name": "企业管理",
        "seq": 0,
        "newWin": false,
        "status": true,
        "url": "/business/business",
        "path": "/business/business",
        "meta": {"title": "企业管理"},
      }]
    }
    ]
  } else {
    const res = await navigationApi.list();
    layoutConf.value.layout = configStore.config.layoutType === 'LEFT' ? 'mix' : 'top'
    if (res) {
      setKeyMenuData(res)
      menuData.data = res
    } else {
      menuData.data = [{
        "id": 7,
        "parentId": null,
        "weight": 4,
        "name": "系统设置",
        "seq": 4,
        "url": "/setting",
        "path": "/setting",
        "meta": {"title": "系统设置"},
        "children": [{
          "id": 9,
          "parentId": 7,
          "weight": 0,
          "name": "导航管理",
          "seq": 0,
          "url": "/setting/nav",
          "path": "/setting/nav",
          "newWin": false,
          "status": true,
          "meta": {"title": "导航管理"}
        }, {
          "name": "基本信息",
          "url": "/setting/index",
          "path": "/setting/index",
          "newWin": false,
          "status": true,
          "meta": {"title": "基本信息"}
        }],
      }]
    }
    // router.push({name: 'welcome'});
  }
  getData()
}
const setKeyMenuData = (data) => {
  for (const item of data) {
    item['path'] = item['url']
    item['meta'] = {'title': item.name}
    if (item.children) {
      setKeyMenuData(item.children)
    }
  }
}
const navClick = (e) => {
  router.push({path: e.key})
}

const topMenuClick = async (e) => {
  let url = e.url;
  if (markUrl((url))) {
    if (store.isAdmin) {
      try {
        url += url.includes("?") ? '&' : '?';
        url += 'sign=' + (await userApi.getCurrentUserSign()).sign
      } catch (e) {
        console.error(e)
      }
    }

    window.open(url)
    return
  }

  e.newWin ? window.open(window.origin + url) : router.push({path: e.path,query:getUrlParams(e.path)})
  isShowMask.value = false
}

const getUrlParams = (url) => {
  return Object.fromEntries(new URLSearchParams(url.split('?')[1]).entries())
}

const markUrl = (url) => {
  return !!(url.includes("http://") || url.includes("https://"));
}
const state = reactive({
  collapsed: false, // default value
  openKeys: [],
  selectedKeys: [],
});

const layoutConf = ref({
  title: configStore.config?.name,
  navTheme: 'dark',
  layout: 'top',
  splitMenus: false,
  fixSiderbar: true,
  logo: configStore.config?.logoUuid ? fileApi.getFile(configStore.config?.logoUuid) : require('@/assets/logo.png'),
});

const reChangeSetting = () => {
  layoutConf.value.title = configStore.config?.name;
  layoutConf.value.logo = configStore.config.logoUuid ? fileApi.getFile(configStore.config.logoUuid) : require('@/assets/logo.png');
}

const changeLayout = async () => {
  layoutConf.value.layout = layoutConf.value.layout == 'mix' ? 'top' : 'mix'
  // layoutConf.value.navTheme = layoutConf.value.navTheme == 'light' ? 'dark' : 'light'
  let setting = Object.assign({}, configStore.config)
  setting.layoutType = layoutConf.value.layout == 'mix' ? 'LEFT' : 'TOP'
  await systemApi.updateSetting(setting);
  await configStore.init();
  document.querySelector('.ant-pro-top-nav-header-menu').style.display = 'none'
}

watch(configStore.config, (val) => {
  getNavList()
}, {deep: true})


watch(router.currentRoute, () => {
  const matched = router.currentRoute.value.matched.concat()

  state.selectedKeys = matched.filter(r => r.name !== 'index').map(r => r.path)
  state.openKeys = matched.filter(r => r.path !== router.currentRoute.value.path).map(r => r.path)
}, {
  immediate: true,
})

const onLogout = () => {
  store.logout();
  const roles = localStorage.getItem("roles")
  if (roles === 'SUPER_ADMIN') {
    localStorage.removeItem("roles")
    router.push({name: "superLogin"})
  } else {
    router.push({name: "login", params: {corpCode: localStorage.getItem("corpCode")}});
  }
}

const isShowMask = ref(false)
const activeKey = ref([]);
const getData = () => {
  menuData.data.forEach(item => {
    if (item.children) {
      item.children.forEach(ele => {
        if (ele.children) {
          activeKey.value.push(ele.id)
        }
      })
    }
  })
}

const navData = computed(() => {
  return menuData.data.filter(ele => ele.status)
})

onMounted(()=> {
  events.on('changeNavigation', () => {
    getNavList()
  })
  events.on('changeSetting', () => {
    reChangeSetting()
  })
})
onBeforeUnmount(()=>{
  events.off('changeNavigation')
  events.off('changeSetting')
})

</script>
<style lang="less">
.ant-pro-top-menu {
  height: 104px !important;
  background: #fff !important;
}

.ant-pro-top-nav-header-logo {
  padding-left: 20px;
  overflow: visible !important;
}

.ant-pro-top-nav-header-logo h1 {
  color: #000 !important;
}

.ant-pro-top-nav-header-main div:nth-child(3) {
  margin-left: auto;
}

.ant-pro-top-nav-header-main {
  padding-left: 0 !important;
}


.ant-pro-top-nav-header-main > .ant-pro-top-nav-header-menu {
  position: absolute;
  background: #001529;
  width: 100%;
  bottom: 0;
  padding-left: 20px;
}
.ant-pro-top-nav-header {
  position: fixed !important;
  height: 56px !important;
  background-color: #fff;
}
.nav-top {
  position: absolute;
  left: 0;
  top: 56px;
  width: 100vw;
  background-color: #041527;
  .one-level {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
    padding-left: 24px;
    width: auto;
    li {
      width: 150px;
      text-align: center;
      list-style: none;
      .first-level-nav {
        color: #A7ADB4;
      }
      .bottom-icon {
        transition: all .5s;
      }
      &:hover {
        background-color: #448EF7;
        .first-level-nav {
          color: #fff;
        }

        .bottom-icon {
          transform: rotate(180deg);
        }
      }
    }

    &:hover .top-nav-mask li {
      background-color: transparent;
    }
  }
}


.top-nav-mask {

  max-height: 0;
  position: fixed;
  top: 104px;
  left: 24px;
  width: 97%;
  height: 60vh;
  padding: 0 0 10px 0;
  background-color: #fff;
  border-radius: 5px;
  z-index: 99;
  transition: all 0.3s ease-in;

  .tow-level {
    display: flex;
    height: 100%;
    padding-left: 0;
    margin-top: 10px;

    li {
      overflow-y: auto;
      overflow-x: hidden;
      width: 150px;
      height: 100%;
      border-right: 1px solid #ccc;
      font-size: 16px;
      text-align: center;
      svg {
        color: #000;
      }

      span {
        color: #000;
        font-size: 13px;
        cursor: pointer;
        padding: 3px 20px;
        border-radius: 8px;
      }

      .none-child:hover {
        color: #448EF7;
        background-color: #F7FAFF;
      }

      .tow-level-nav {
        padding-top: 10px;

        span {
          padding: 3px 20px;
          border-radius: 8px;
        }

        & > li > span:hover {
          color: #448EF7;
          background-color: #F7FAFF;
        }

        .have-three-level-nav {
          span {
            color: #aaa;
          }

          li span:hover {
            color: #448EF7;
            background-color: #F7FAFF;
          }
        }
        &>li {
          line-height: 30px;
          font-size: 13px;
        }
      }
      .have-three-level-nav {
        li {
          border-right: none;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .ant-collapse {
      border: none;
      background-color: #fff;
      .ant-collapse-header {
        display: block;
        padding: 5px 0;
        font-size: 13px;
      }
    }
    .ant-collapse-content {
      border-top: none;
      li {
        font-size: 13px;
        // margin-bottom: 10px;
      }
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: none;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 16px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      margin-right: -16px;
    }
  }
}


.top-nav-mask-show {
  box-shadow: 0px 1px 10px -2px;
  max-height: 1000px ;
}
</style>
