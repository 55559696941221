import dayjs from "dayjs";
import {useConfigStore} from "@shared/store/useConfigStore";

export const filters = {
    datetime(value, format = 'YYYY-MM-DD HH:mm:ss') {
        if (!value) {
            return "";
        }
        return dayjs(value).format(format);
    },
    dictionaryName(value) {
        if (!value) {
            return "";
        }

        let configStore = useConfigStore();
        let dictionaryEl = configStore.getDictionary[value];
        if (dictionaryEl) {
            return dictionaryEl.value
        }

        return "";
    }
}
