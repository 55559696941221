import {client} from "@shared/api-client";

/*
企业名称: name
企业编码: code
统一社会信用代码: creditCode
企业类型: categorizeId
企业规模: scale
所属地区: region
详细地址: address
法人名称: legalPerson
法人电话: legalPhone
联系人名称: contactName
联系人电话: contactPhone
负责人名称: leaderName
负责人电话: leaderPhone
*/
export const organizationApi = {
    create: async (params) => {
        return client.post("/api-admin/organization/create", params);
    },
    search: async (params) => {
        return client.get("/api-admin/organization/search", {params: params});
    },
    list: async (params) => {
        return client.get("/api-admin/organization/list", {params: params});
    },
    departmentTree: async (params) => {
        return client.get("/api-admin/organization/departmentTree", {params: params});
    },
    orgDepartmentTree: async (params) => {
        return client.get("/api-admin/organization/orgDepartmentTree", {params: params});
    },
    update: async (params) => {
        return client.post("/api-admin/organization/update", params);
    },
    delete: async (params = {id: 0}) => {
        return client.post("/api-admin/organization/delete", params);
    },
    checkCodeExist: async (params = {id: 0, code: ""}) => {
        return client.post("/api-admin/organization/checkCodeExist", params);
    },
    getCurrentOrganization: async () => {
        return client.get("/api-admin/setting/getEnterpriseInfo");
    },
    updateCurrentOrganization: async (params) => {
        return client.post("/api-admin/setting/updateEnterpriseInfo", params);
    },
    getById: async (id) => {
        return client.get("/api-admin/organization/getById", {params: {id}});
    },
    sort: async (params) => {
        return client.post("/api-admin/organization/sort", params);
    },
    export:  () => {
        return "/api-admin/organization/export";
    },
}
