import {defineStore} from 'pinia'
import router from '@/router';
import {superAdminRoutes} from "@/router/superAdmin";
import {adminRoutes} from "@/router/admin";
import {useAuthStore} from "@shared/store/useAuthStore";

const roleRoutes = {
    'SUPER_ADMIN': superAdminRoutes,
    'ADMIN': adminRoutes,
}
const addRouter = (router, routes) => {
    routes.forEach(route => router.addRoute(route))
}
export const useStore = defineStore('main', {
    state: () => {
        return {
            // 所有这些属性都将自动推断其类型
            roles: '',
            isHasRouter: false,
        }
    },

    actions: {
        setRoles(payload) {
            this.roles = payload
            localStorage.setItem("roles", payload)
            addRouter(router, roleRoutes[payload])
        },
        setRouteList() {
            if (this.isHasRouter) return

            let roles = localStorage.getItem("roles")
            let authStore = useAuthStore();
            if (!roles) {
                authStore.logout()
                return
            }

            if (authStore.isAdmin) {
                setRoutersCorpCode(adminRoutes, authStore.getUser.corpCode)
            }

            addRouter(router, roleRoutes[roles])
            // 修改路由获取状态
            this.isHasRouter = true
        },
    },
})


const setRoutersCorpCode = (routes, corpCode) => {
    setRoutersPathCorpCode(routes, corpCode);
    setRoutersRedirectCorpCode(routes, corpCode)
}

const setRoutersRedirectCorpCode = (routes, corpCode) => {
    routes.forEach(route => {
        if (route.redirect && typeof route.redirect === 'string') {
            route.redirect = "/" + corpCode + route.redirect
        }

        if (route.children) {
            setRoutersRedirectCorpCode(route.children, corpCode)
        }
    })
}

const setRoutersPathCorpCode = (routes, corpCode) => {
    routes.forEach(route => {
        if (!route.notCorp) {
            route.path = "/" + corpCode + route.path
            route.meta = {...route.meta, isCorp: true, corpCode: corpCode};
        }
    })
}
