import {client} from "@shared/api-client";

export const navigationApi = {
    create: async (params = {
        parentId: 0,
        name: '',
        url: '',
        type: '',
        contentLibraryId: '',
        contentCategoryId: '',
        blank: false,
        status: false
    }) => {
        return client.post("/api-admin/navigation/create", params);
    },
    list: async () => {
        return client.get("/api-admin/navigation/list");
    },
    delete: async (id) => {
        return client.post(`/api-admin/navigation/delete`, {id});
    },
    update: async (params = {
        id: 0,
        name: '',
        url: '',
        type: '',
        contentLibraryId: '',
        contentCategoryId: '',
        blank: false,
        status: false
    }) => {
        return client.post("/api-admin/navigation/update", params);
    },
    updateAll: async (params = {id: 0, children: {}}) => {
        return client.post("/api-admin/navigation/updateAll", params);
    }

}
