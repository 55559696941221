import {client} from "@shared/api-client";

/*
大纲名称: name
大纲类型id: trainingOutlineCategorizeId
*/
export const trainingOutlineApi = {
    create: async (params) => {
        return client.post("/api-admin/trainingOutline/create", params);
    },
    search: async (params) => {
        return client.get("/api-admin/trainingOutline/search", {params: params});
    },
    list: async (params) => {
        return client.get("/api-admin/trainingOutline/list", {params: params});
    },
    update: async (params) => {
        return client.post("/api-admin/trainingOutline/update", params);
    },
    delete: async (params = {id: 0}) => {
        return client.post("/api-admin/trainingOutline/delete", params);
    },
    getById(id) {
        return client.get("/api-admin/trainingOutline/getById", {params: {id}});
    }
}

