import {useAuthStore} from "@shared/store/useAuthStore";
import {useConfigStore} from "@shared/store/useConfigStore";

const originSetItem = localStorage.setItem;
const originGetItem = localStorage.getItem;
const originRemoveItem = localStorage.removeItem;

const changeLocalStorage = (corpCode) => {
    originSetItem.call(localStorage, "currentCorpCode", corpCode);
    localStorage.setItem = (k, v) => originSetItem.call(localStorage, corpCode + ":" + k, v)
    localStorage.getItem = (k) => originGetItem.call(localStorage, corpCode + ":" + k)
    localStorage.removeItem = (k) => originRemoveItem.call(localStorage, corpCode + ":" + k)
}

export async function bootStore() {
    let pathname = window.location.pathname;
    let sec = pathname.indexOf("/", 1);
    if (sec === -1) {
        sec = pathname.length;
    }
    let corp = pathname.substring(1, sec);
    if (corp === "sso-login") {
        corp = originGetItem.call(localStorage, "currentCorpCode")
    }
    if (!corp) {
        corp = originGetItem.call(localStorage, "currentCorpCode")
    }

    changeLocalStorage(corp);
    const store = useAuthStore();
    store.init();

    const configStore = useConfigStore();
    await configStore.init();
}
