import BlankLayout from "@/layouts/BlankLayout";
import BasicLayout from "@/layouts/BasicLayout";
import {orgCategorizeApi} from "@/api/orgCategorize";
import {organizationApi} from "@/api/organization";
import {trainingOutlineApi} from "@/api/trainingOutline";


export const adminRoutes = [
    {
        path: '/',
        redirect: '/',
        notCorp: true
    },
    {
        path: '/',
        name: 'index',
        meta: {title: 'Home'},
        component: BasicLayout,
        redirect: '/welcome',
        children: [
            {
                path: 'welcome',
                name: 'welcome',
                meta: {title: '欢迎'},
                component: () => import('../views/WelcomePage.vue'),
            },
            {
                path: 'student/record',
                name: 'studentRecord',
                meta: {title: '一人一档'},
                component: () => import('../views/student/StudentManagePage.vue'),
            },
            {
                path: 'class/record',
                name: 'classRecord',
                meta: {title: '一期一档'},
                component: () => import('../views/classroom/TrainManage.vue'),
            },
            {
                path: 'student',
                name: 'student',
                meta: {title: '学员管理', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: '/student/student',
                children: [
                    {
                        path: 'student',
                        name: 'studentManage',
                        meta: {title: '学员管理'},
                        component: () => import('../views/student/StudentManagePage.vue'),
                    },
                ]
            },
            {
                path: 'train',
                name: 'train', meta: {title: '培训管理', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: 'train/train',
                children: [
                    {
                        path: 'train',
                        name: 'trainManage',
                        meta: {title: '培训管理'},
                        component: () => import('../views/classroom/TrainManage.vue'),
                    }
                ]

            },
            {
                path: 'user',
                name: 'user',
                meta: {title: '用户管理', icon: 'UserOutlined'},
                component: BlankLayout,
                redirect: '/user/index',
                children: [
                    {
                        path: 'index',
                        name: 'user_index',
                        meta: {title: '用户管理'},
                        component: () => import('../views/UserPage.vue'),
                    },
                ]
            },
            {
                path: 'setting',
                name: 'setting',
                meta: {title: '系统设置', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: 'setting/index',
                children: [
                    {
                        path: 'index',
                        name: 'setting_index',
                        meta: {title: '基本信息'},
                        component: () => import('../views/setting/setting.vue'),
                    },
                    {
                        path: 'nav',
                        name: 'nav_index',
                        meta: {title: '导航管理'},
                        component: () => import('../views/navigation/NavigationSetting.vue'),
                    },
                    {
                        path: 'shortcut',
                        name: 'shortCurtSetting',
                        meta: {title: '快捷方式'},
                        component: () => import('../views/shortcut/shortcutSetting.vue'),
                    },
                    {
                        path: 'dictionary',
                        name: 'dictionarySetting',
                        meta: {title: '字典管理'},
                        component: () => import('../views/dictionary/DictionaryPage.vue'),
                    },
                    {
                        path: 'orgInfo',
                        name: 'orgInfoSetting',
                        meta: {title: '企业信息'},
                        component: () => import('../views/org/CurrentOrgInfoPage.vue'),
                    },
                ]
            },
            {
                path: 'org',
                name: 'org',
                meta: {title: '组织机构', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: '/org/categorize',
                children: [
                    {
                        path: 'categorize',
                        name: 'orgCategorize',
                        meta: {title: '机构类型'},
                        component: BlankLayout,
                        redirect: {name: 'orgCategorizeList'},
                        children: [
                            {
                                path: '',
                                name: 'orgCategorizeList',
                                component: () => import('../views/org/OrgCategorizePage.vue'),
                            },
                            {
                                path: ':orgCategorizeId/organization',
                                name: 'orgIndex',
                                redirect: {name: 'orgIndexList'},
                                meta: {
                                    title: '机构管理', breadcrumbConfig: {
                                        api: async (id) => (await orgCategorizeApi.getById(id)).name,
                                        keyName: 'orgCategorizeId'
                                    }
                                },
                                component: BlankLayout,
                                children: [
                                    {
                                        path: '',
                                        name: 'orgIndexList',
                                        component: () => import('../views/org/OrganizationPage.vue'),
                                    },
                                    {
                                        path: ':organizationId/orgDepartment',
                                        name: 'orgDepartment',
                                        meta: {
                                            title: '部门管理', breadcrumbConfig: {
                                                api: async (id) => (await organizationApi.getById(id)).name,
                                                keyName: 'organizationId'
                                            }
                                        },
                                        component: () => import('../views/org/OrgDepartmentPage.vue'),
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'post',
                        name: 'orgPost',
                        meta: {title: '岗位管理'},
                        component: () => import('../views/org/PostPage.vue'),
                    },
                    {
                        path: 'postMap',
                        name: 'postMap',
                        meta: {title: '岗位矩阵管理'},
                        redirect: {name: 'postMapIndex'},
                        children: [
                            {
                                path: 'index',
                                name: 'postMapIndex',
                                meta: {title: '岗位矩阵管理'},
                                component: () => import('../views/org/PostMapPage')
                            },
                            {
                                path: ':id/show',
                                name: 'postMapShow',
                                meta: {title: '岗位矩阵'},
                                component: () => import('../views/org/PostMapShowPage')
                            }
                        ],

                    }
                ]
            },
            {
                path: 'entrust',
                name: 'entrust',
                meta: {title: '委托', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: 'entrust/training',
                children: [
                    {
                        path: 'training',
                        name: 'entrustTraining',
                        meta: {title: '委托培训'},
                        component: () => import('../views/entrust/EntrustTrainingOrgPage.vue'),
                    },
                    {
                        path: 'trainingRequirement',
                        name: 'entrustTrainingRequirement',
                        meta: {title: '委托培训需求'},
                        component: () => import('../views/entrust/EntrustTrainingRequirementPage.vue'),
                    }
                ]
            },
            {
                path: 'certificate',
                name: 'certificate',
                meta: {title: '证书', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: 'certificate/index',
                children: [
                    {
                        path: 'index',
                        name: 'certificateIndex',
                        meta: {title: '证书管理'},
                        component: () => import('../views/certificate/CertificatePage.vue'),
                    },
                    {
                        path: 'category',
                        name: 'certificateCategory',
                        meta: {title: '证书类型管理'},
                        component: () => import('../views/certificate/CertificateCategoryPage.vue'),
                    },
                    {
                        path: 'tobeCer',
                        name: 'toBeStudentCertificateManage',
                        meta: { title: '待获取证书'},
                        component: () => import('../views/certificate/StudentCertificateManagePage.vue')
                    },
                    {
                        path: 'validCer',
                        name: 'validStudentCertificateManage',
                        meta: { title: '已获取证书'},
                        component: () => import('../views/certificate/ValidStudentCertificateManagePage.vue')
                    }
                ]
            },
            {
                path: 'trainingOutline',
                name: 'trainingOutline',
                meta: {title: '培训大纲', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: {name: 'trainingOutlineIndex'},
                children: [
                    {
                        path: 'index',
                        name: 'trainingOutlineIndex',
                        meta: {title: '培训大纲'},
                        component: () => import('../views/training/TrainingOutlinePage.vue'),
                    },
                    {
                        path: 'category',
                        name: 'trainingOutlineCategory',
                        meta: {title: '大纲类型'},
                        component: () => import('../views/training/TrainingOutlineCategorizePage.vue'),
                    },
                    {
                        path: ':trainingOutlineId/course',
                        name: 'trainingOutlineCourse',
                        meta: {
                            title: '大纲课程', breadcrumbConfig: {
                                api: async (id) => (await trainingOutlineApi.getById(id)).name,
                                keyName: 'trainingOutlineId'
                            }
                        },
                        component: () => import('../views/training/CoursePage.vue'),
                    }
                ]
            },
            {
                path: 'trainingProgram',
                name: 'trainingProgram',
                meta: {title: '培训计划', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: 'trainingProgram/index',
                children: [
                    {
                        path: 'index',
                        name: 'trainingProgramIndex',
                        meta: {title: '培训计划'},
                        component: () => import('../views/trainingProgram/TrainingProgramPage.vue'),
                    },{
                        path: 'showFee',
                        name: 'trainingProgramShowFee',
                        meta: {title: '经费计划'},
                        component: () => import('../views/trainingProgram/TrainingProgramFeeShowPage.vue'),
                    },{
                        path: 'showCourse',
                        name: 'trainingProgramShowCourse',
                        meta: {title: '课程计划'},
                        component: () => import('../views/trainingProgram/TrainingProgramCourseShowPage.vue'),
                    }
                ]
            }
        ]
    },
    {
        path: '/studentTable',
        name: 'studentTable',
        component: () => import('../views/student/common/TableRecord.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {title: '数据大屏'},
        component: () => import('../views/dashboard/index.vue'),
    },

]

