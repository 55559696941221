import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@shared/store/useAuthStore";
import {useStore} from "@shared/store/useRouterStore";
import {useConfigStore} from "@shared/store/useConfigStore";

const routes = [
    {
        path: '/:corpCode/login',
        name: 'login',
        meta: {title: '登录', requiredAuth: false},
        component: () => import('../views/login/LoginPage.vue'),
    },
    {
        path: '/sso-login',
        name: 'ssoLogin',
        meta: {requiredAuth: false},
        component: () => import('../views/login/SsoLogin.vue'),
    },
    {
        path: '/superlogin',
        name: 'superLogin',
        meta: {title: '平台管理员登录', requiredAuth: false},
        component: () => import('../views/login/superLoginPage.vue'),
    },
    {
        path: '/:catchAll(.*)',
        hidden: true,
        component: () => import('../views/404/404.vue')//这个是我自己的路径
    }

]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    let config = useConfigStore();
    if (to.meta.title) {
        document.title = to.meta.title + " | " + config.$state.config.name;
    }

    if (to.meta.requiredAuth === undefined || to.meta.requiredAuth === true) {
        const authStore = useAuthStore();
        if (!authStore || !authStore.isLogin) {
            next({
                name: "login",
                params: {corpCode: window.originGetItem.call(localStorage, "currentCorpCode") ?? 'base'}
            });
            return
        } else {
            const store = useStore();
            if (!store.isHasRouter) {
                store.setRouteList()

                next({...to, replace: true})
                return;
            } else {
                next()
                return;
            }
        }
    }

    next();
});


export default router
