import {client} from "@shared/api-client";

/*
业务分类: type
值: value
*/
export const dictionaryApi = {
    create: async (params) => {
        return client.post("/api-admin/dictionary/create", params);
    },
    findByType: async (params) => {
        return client.get("/api-admin/dictionary/findByType", {params: params});
    },
    findAll: async () => {
        return client.get("/api-admin/dictionary/findAll");
    },
    update: async (params) => {
        return client.post("/api-admin/dictionary/update", params);
    },
    delete: async (params = {id: 0}) => {
        return client.post("/api-admin/dictionary/delete", params);
    }
}

