import {client} from "@shared/api-client";

export const userApi = {


    create: async (params) => {
        return client.post("/api-admin/user/create", params);
    },

    search: async (params) => {
        return client.get("/api-admin/user/search", {params: params});
    },
    resetPassword: async (params = {id: '', targetPassword: ''}) => {
        return client.post("/api-admin/user/resetPassword", params);
    },
    // 修改手机号
    editMobile: async (params = {id: '', targetMobile: ''}) => {
        return client.post("/api-admin/user/editMobile", params);
    },
    // 禁用用户
    unlock: async (params = {id: ''}) => {
        return client.post("/api-admin/user/unlock", params);
    },
    // 启用用户
    lock: async (params = {id: ''}) => {
        return client.post("/api-admin/user/lock", params);
    },
    // 获取所有角色
    getRoles: async () => {
        return client.get("/api-admin/user/getRoleList");
    },
    getCurrentUserSign: async () => {
        return client.get("/api-admin/user/getCurrentUserSign");
    }
}
